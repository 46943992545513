import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import Navigation from './../layouts/Navigation.jsx';
import Footer from './../layouts/Footer.jsx';


const PrivacyPolicy = lazy(() => import('./../pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./../pages/TermsAndConditions'));
const ContactUs = lazy(() => import('./../pages/ContactUs'));
const About = lazy(() => import('./../pages/About'));

const LandingPage = lazy(() => import('./../pages/LandingPage'));

const CookieConsentBanner = lazy(() => import('./../layouts/CookieConsentBanner'));

const SignUp = lazy(() => import('./../features/authentication/components/SignUp'));
const Login = lazy(() => import('./../features/authentication/components/Login'));

const ModelGarden = lazy(() => import('./../features/modelGarden/components/ModelGarden'))
const ModelPageView = lazy(() => import('./../features/modelGarden/components/ModelPageView'))
const ModelChatViewer = lazy(() => import('./../features/modelGarden/components/ModelChatViewer'))

const Chat = lazy(() => import('./../features/modelChat/components/Chat'))

const ChatEditor = lazy(() => import('./../features/chatEditor/components/ChatEditor'))

const ModelCreatePage = lazy(() => import('./../features/modelCreator/components/ModelCreatePage'))

const ApplicationStudio = lazy(() => import('./../features/applicationStudio/ApplicationStudio.jsx'));

const ApplicationPage = lazy(() => import('./../features/applicationPage/ApplicationPage.jsx'));

const ApplicationPageInterface = lazy(() => import('./../features/applicationPageInterface/ApplicationPageInterface.jsx'));

const AppLibrary = lazy(() => import('./../features/appLibrary/AppLibrary.jsx'));

const AccountPage = lazy(() => import('./../features/accountPage/AccountPage.jsx'));

const CreateApplicationPage = lazy(() => import('./../features/applicationStudio/CreateApplicationPage.jsx'));

const Waitlist = lazy(() => import('./../features/waitList/Waitlist.jsx'));
const WaitlistFF = lazy(() => import('./../features/waitList/WaitlistFF.jsx'));

const WaitlistNavigation = lazy(() => import('./../features/waitList/WaitlistNavigation.jsx'));


function MainRouter() {
  return (
	<StyledEngineProvider injectFirst>
		<Router>
			<div className='appContainer'>
			<WaitlistNavigation />
			<Suspense fallback={<div className='mainLoadingDiv'>Loading...</div>}>
			<Routes>
				<Route path='/ff' element={ <WaitlistFF /> } />
				<Route path='/*' element={ <Waitlist /> } />
			</Routes>
			</Suspense>
			</div>
			<Suspense fallback={<div>Loading Cookie Banner</div>}>
			</Suspense>
		</Router>
	</StyledEngineProvider>
  );
}

export default MainRouter;
