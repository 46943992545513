import './App.css';
import MainRouter from './components/MainRouter.jsx';

function App() {
  return (
    <div className="App">
	    <MainRouter />
    </div>
  );
}

export default App;
