import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

function Footer() {
	return (
		<div>
		<div className='divHolder'></div>
		<Grid container className='footerGridContainer'>
			<Grid item lg={4} md={4} sm={4} xs={4} className='footerGridItemLeft'>
				<Link to='/privacy-policy' className="footerLink">
					<Typography className="footerTextLeft">
						Privacy Policy
					</Typography>
				</Link>
				<Link to='/terms-and-conditions' className="footerLink">
					<Typography className="footerTextLeft">
						Terms and Conditions
					</Typography>
				</Link>
				<Link to='/contact-us' className="footerLink">
					<Typography className="footerTextLeft">
						Contact Us
					</Typography>
				</Link>
			</Grid>
			<Grid item lg={4} md={4} sm={4} xs={4} className='footerGridItem'>
				<Link to='/articles/all' className="footerLink">
					<Typography className="footerText">
						All Articles
					</Typography>
				</Link>
				<Link to="/tags" className="footerLink">
					<Typography className="footerText">
						All Topics
					</Typography>
				</Link>
			</Grid>
			<Grid item lg={4} md={4} sm={4} xs={4} className='footerGridItemRight'>
				<Link to='/about' className="footerLink">
					<Typography className="footerTextRight">
						About
					</Typography>
				</Link>
			</Grid>
		</Grid>
		</div>
	)
}

export default Footer;
